
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

@Component
export default class OrgCard extends Mixins(VuetifyMixin){
	@Prop({ type: Boolean, default: false }) private loading: boolean;
	@Prop({ required: true }) private organization: OrganizationModel;
}
