
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';

@Component
export default class SoccerCardSvg extends Mixins(VuetifyMixin){
	@Prop({ default: '0.20' }) private opacity: string;
	@Prop({ default: 176 }) private width: number;
	@Prop({ default: 146 }) private height: number;
	@Prop() private color: string;
	get Color(): string{
		if(!this.color) return this.getColor('baColorSportCardIconLight');
		return this.getColor(this.color);
	}
}
