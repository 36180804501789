
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { title } from '@/pipes/title.pipe';
import { mdiChevronDown, mdiMagnify } from '@mdi/js';
import OrgAdminModule from '../../store/orgAdmin/OrgAdmin.store';
import { getModule } from 'vuex-module-decorators'
const orgAdminStore = getModule(OrgAdminModule);
import { OrgResolverMixin } from '@/mixins/OrgResolverMixin';
import Page from '@/views/Page.vue';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import OrgTeamCard from '@/components/orgAdmin/OrgTeamCard.vue';
import { TeamModel } from '@/models/team';
import { DebounceMixin, SportsSelectorMixin } from '@/mixins';
import { SportName, Gender, TeamGenderValues } from '@/../types/enums';
import { AgeGroup, AgeGroupValues } from '@best-athletes/ba-types';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';

import TeamMiniCard from "@/components/ui/TeamMiniCard.vue";

@Component({
	components: {
		OrgTeamCard,
		Page,
		OrgCard,
		TeamMiniCard
	}
})
export default class OrgAdminManageTeams extends Mixins(SportsSelectorMixin, DebounceMixin, OrgResolverMixin, VuetifyMixin){
	titleCase = title;
	iconExpandList = mdiChevronDown;
	iconSearch = mdiMagnify;
	showTeamSelector = false;

	created(): void{
		this.loadOrgTeams();
	}

	get TableLoading(): boolean{
		return !orgAdminStore.loadOrganizationTeamsInitialized || orgAdminStore.loadOrganizationTeamsLoading;
	}

	loadOrgTeams(): Promise<void>{
		return orgAdminStore.loadOrganizationTeams({organizationId: this.CurrentOrganizationId});
	}

	searchLoading: boolean = false;
	debounceSearch(): void{
		this.searchLoading = true;
		this.debounceCallback('teamSearch', () => {
			this.searchRegExp = new RegExp(this.teamSearch, 'ig');
			this.searchLoading = false;
		}, 300);
	}

	teamSearch: string = "";
	sportsFilter: SportName[] = [];
	genderFilter: Gender[] = [];
	ageFilter: AgeGroup[] = [];
	searchRegExp: RegExp | null = null;
	get TeamList(): TeamModel[]{
		const filteredTeams = orgAdminStore.teamList
			.filter(t => this.sportsFilter.length === 0 ? t : this.sportsFilter.includes(t.sportId as SportName))
			.filter(t => this.genderFilter.length === 0 ? t : this.genderFilter.includes(t.gender as Gender))
			// TODO: [HIN-732] Add age group to team
			// .filter(t => this.ageFilter.includes(t.ageGroup))
		if(this.searchRegExp === null) return filteredTeams;
		return filteredTeams.filter(t => this.searchRegExp.test(t.name));
	}
	get TeamGenderValues(): Gender[]{
		return TeamGenderValues;
	}
	get AgeGroupValues(): AgeGroup[]{
		return AgeGroupValues;
	}
	get UnaffiliatedTeams(): TeamModel[]{
		const allTeams = orgAdminStore.teamList;
		return allTeams;
	}

	openTeam(teamId: string): void{
		this.$router.push({
			name: OrgAdminRoutes.TeamDashboard,
			params:{
				teamId
			}
		})
	}

	onClickTeam(item, event) {
		this.openTeam(item.id)
	}

	createTeam(): void{
		this.$router.push({
			name: OrgAdminRoutes.TeamCreate,
		});
	}

	// Data Table elements
	TeamData(): Array<any> {
		let teams = [];
		let teamsList = this.TeamList;

		for( let team of teamsList ){
			teams.push({
				id: team.id,
				team: team,
				name: team.name,
				age: 0,
				players: team.players.length,
				gender: team.gender,
				location: team.city,
				coach: team.HeadCoachId
			})
		}

		return teams;
	}
	teamHeaders = [
		{
			text: 'Team',
			align: 'start',
			sortable: true,
			value: 'name',
		},
		{
			text: 'Players',
			value: 'players',
		},
		/* TODO: Add age group to teams
		{
			text: 'Age Group',
			value: 'age'
		},
		*/
		{
			text: 'Gender',
			value: 'gender',
		},
		{
			text: 'Location',
			value: 'location',
		},
		/* TODO: Add head coach
		{
			text: 'Head Coach',
			value: 'coach'
		}
		*/
	]
}
