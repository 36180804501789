
import { Component, Mixins } from 'vue-property-decorator';
import OrgAdminModule from '../../store/orgAdmin/OrgAdmin.store';
import { getModule } from 'vuex-module-decorators'
const orgAdminStore = getModule(OrgAdminModule);
import { VuetifyMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

@Component({
	components: {
		Page,
		OrgCard,
	}
})
export default class OrgAdminDashboard extends Mixins(VuetifyMixin){
	created(): void{
		orgAdminStore.loadOrganizations();
	}

	get Loading(): boolean{
		return !orgAdminStore.organizationsInitialized || orgAdminStore.organizationsLoading;
	}
	get OrgsReady(): boolean{
		return orgAdminStore.organizationsInitialized;
	}
	get Organizations(): OrganizationModel[]{
		return orgAdminStore.organizations;
	}

	openOrg(organizationId: string): void{
		this.$router.push({
			name: OrgAdminRoutes.OrgAdminManageTeams,
			params:{
				organizationId,
			}
		});
	}

}
