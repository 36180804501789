
import { Component, Mixins } from 'vue-property-decorator';
import { mdiChevronDown } from '@mdi/js';
import OrgAdminModule from '../../store/orgAdmin/OrgAdmin.store';
import { getModule } from 'vuex-module-decorators'
const orgAdminStore = getModule(OrgAdminModule);
import { OrgResolverMixin } from '@/mixins/OrgResolverMixin';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import Page from '@/views/Page.vue';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import SortIcon from '@/components/ui/SortIcon.vue';
import { DebounceMixin, LocalForageMixin } from '@/mixins';
import { title } from '@/pipes/title.pipe';
import { Gender, PerformanceCategory, getPerformanceCategoryValues } from '@/../types/enums';
import { AthleteMetric, AthleteMetricValues, FourCornersMetric, SixMomentsMetric, CrispMetric, PersonalityMetric } from '@/../types/enums';
import { AgeGroup, AgeGroupValues } from '@best-athletes/ba-types';
import { RepositoryQuery, QueryOptions } from '@/../types/interfaces';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import ChipGroup from '@/components/forms/ChipGroup.vue';
import TeamsSummary from '@/components/orgAdmin/TeamsSummary.vue';
import AssessmentDataInfoProvider from '@/components/hoc/AssessmentDataInfoProvider.vue';
import { performanceCategoryToColor, performanceCategoryToLabel } from '@/pipes/enum.pipes';
import { assessmentToMetric } from '@/pipes/assessment-to-metric.pipe';
import { DataTableHeader, DataOptions } from 'vuetify';
import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';
import { CoachViewAthlete } from '@/../types/constants/web_client_user.routes';

@Component({
	components: {
		AssessmentDataInfoProvider,
		ConfirmationDialog,
		ChipGroup,
		OrgCard,
		Page,
		SortIcon,
		TeamsSummary,
	}
})
export default class OrgAdminManagePlayers extends Mixins(DebounceMixin, LocalForageMixin, OrgResolverMixin, VuetifyMixin){
	assessmentToMetric = assessmentToMetric;
	titleCase = title;

	iconDown = mdiChevronDown;

	// called when item is created - perform default search
	created(): void{
		this.debounceSearch();
	}

	// viewStats indicates which stats should be displayed
	viewStats = "physical-verified";

	get isViewPhysical(): boolean {
		return this.viewStats === "physical-verified";
	}
	get isViewFourCorners(): boolean {
		return this.viewStats === "scouting-fourcorners";
	}
	get isViewSixMoments(): boolean {
		return this.viewStats === "scouting-sixmoments";
	}
	get isViewCrisp(): boolean {
		return this.viewStats === "scouting-crisp";
	}
	get isViewPersonality(): boolean {
		return this.viewStats === "personality";
	}

	// obtain physical assessment value in human readable string format
	getPhysicalAssessmentValueString(item: AthleteAssessmentDataModel, metric: AthleteMetric): string{
		const metricData = assessmentToMetric(item, metric);
		return metricData ? `${metricData.value}${metricData.unit}` : '';
	}
	getLabel(item: AthleteAssessmentDataModel, metric: AthleteMetric): string{
		const metricData = assessmentToMetric(item, metric);
		return metricData ? metricData.label : '';
	}


	performanceCategoryToColor = performanceCategoryToColor;
	performanceCategoryToLabel = performanceCategoryToLabel;
	get PerformanceCategories(): PerformanceCategory[]{
		return getPerformanceCategoryValues(true);
	}

	resetFilters(): void{
		this.playerSearch = "";
		this.ageFilter = [];
		this.genderFilter = [];
		this.performanceCategoryFilters = this.emptyPerfFilters();
		this.debounceSearch();
	}

	private emptyPerfFilters(): Record<AthleteMetric, Array<PerformanceCategory>>{
		const filters = AthleteMetricValues.reduce((filters, metric) => {
			filters[metric] = [];
			return filters;
		}, {});
		return filters as Record<AthleteMetric, Array<PerformanceCategory>>;
	}
	performanceCategoryFilters = this.emptyPerfFilters();

	ageFilter: AgeGroup[] = [];
	get AgeGroupValues(): AgeGroup[]{
		return AgeGroupValues;
	}
	get AgeFilter(): number[]{
		return this.ageFilter.map(ageGroup => {
			return parseInt(ageGroup.replace(/[A-Za-z]/, ''));
		});
	}

	genderFilter: Gender[] = [];
	get TeamGenderValues(): Gender[]{
		return [
			Gender.Male,
			Gender.Female,
		];
	}
	maxNumOfPlayer: number = 0;
	get TotalPlayers(): Record<any,number>{
		this.maxNumOfPlayer = Math.max(this.maxNumOfPlayer,orgAdminStore.totalPlayers);
		return {
			'totalMax' :this.maxNumOfPlayer,
			'filteredMax': orgAdminStore.totalPlayers
		};
	}
	get PlayerList(): AthleteProfileModel[]{
		return orgAdminStore.playersList;
	}

	searchLoading: boolean = false;
	playerSearch: string = "";
	debounceSearch(): void{
		this.searchLoading = true;
		this.debounceCallback('playerSearch', () => {
			this.queryOrgPlayers();
		}, 300);
	}
	async queryOrgPlayers(): Promise<void>{
		this.searchLoading = true;
		// TODO: Add age groups to this model
		const query: RepositoryQuery<any> = {
			search: this.playerSearch,
			fields: [ "firstName", "lastName" ],
		};

		let options: QueryOptions = { 
			page: this.tableOptions.page,
			limitPerPage: this.tableOptions.itemsPerPage,
		};

		if (this.tableOptions.itemsPerPage == -1){
			options = { 
				page: this.tableOptions.page,
				limitPerPage: 72,
			}
		}

		if(this.tableOptions.sortBy.length > 0){
			options.sort = {
				fields: this.tableOptions.sortBy.map((field, index) => {
					return {
						field: field,
						desc: this.tableOptions.sortDesc[index],
					};
				}),
			};
		}
		if(this.AgeFilter.length > 0){
			query.$match = {
				...query.$match,
				Age:{
					$any: this.AgeFilter
				}
			}
		}
		if(this.genderFilter.length > 0){
			query.$match = {
				...query.$match,
				gender:{
					// Search for "Male" and "male" since the gender isn't using enums everywhere
					$any: this.genderFilter.map(g => [g.toLowerCase(), title(g)]).reduce((a,b) => [...a,...b]),
				}
			}
		}
		for(const metric of Object.keys(this.performanceCategoryFilters)){
			if(this.performanceCategoryFilters[metric].length > 0){
				query.$match = {
					...query.$match,
					[`lastAssessmentComparison.${metric}`]: {
						$any: this.performanceCategoryFilters[metric]
					}
				}
			}
		}
		this.searchLoading = true;
		await orgAdminStore.loadOrganizationPlayers({ organizationId: this.CurrentOrganizationId, query, options });
		this.searchLoading = false;
	}

	headersBasic: Array<DataTableHeader<any>> = [
		{
			text: 'Name',
			value: 'FullName',
			sortable: false,
		},
		{
			text: 'Age',
			value: 'birthDate',
			sortable: false,
		},
		{
			text: 'Gender',
			value: 'gender',
			sortable: false,
		},
		{
			text: 'Team',
			value: 'teams',
			sortable: false,
		},
		/** TODO - Add Position
		{
			text: 'Pos',
			value: 'primaryPosition',
			sortable: false,
		},
		 */
	]

	// headers for physical assessments
	headersPhysical: Array<DataTableHeader<any> & { metric?: AthleteMetric }> = [
		{
			text: 'Speed',
			value: 'lastAssessment.twentyToThirtyFiveMeterSplit',
			sortable: true,
			class: "no-wrap",
			metric: AthleteMetric.Speed,
		},
		{
			text: 'Accel',
			value: 'lastAssessment.tenMeterSprint',
			sortable: true,
			metric: AthleteMetric.Acceleration,
		},
		{
			text: 'Agility',
			value: 'lastAssessment.reactiveStrengthIndex',
			sortable: true,
			metric: AthleteMetric.Agility,
		},
		{
			text: 'Power',
			value: 'lastAssessment.counterMovementJumpHeight',
			sortable: true,
			metric: AthleteMetric.Power,
		},
		{
			text: 'Recovery',
			value: 'lastAssessment.yoyoIntermittentRecoveryTestStage',
			sortable: true,
			metric: AthleteMetric.Recovery,
		},
	]

	headersFourCorners: Array<DataTableHeader<any> & { metric?: FourCornersMetric}> = [
		{
			text: title(FourCornersMetric.Technical),
			value: FourCornersMetric.Technical,
			sortable: true,
			metric: FourCornersMetric.Technical,
		},
		{
			text: title(FourCornersMetric.Tactical),
			value: FourCornersMetric.Tactical,
			sortable: true,
			metric: FourCornersMetric.Tactical,
		},
		{
			text: title(FourCornersMetric.Physical),
			value: FourCornersMetric.Physical,
			sortable: true,
			metric: FourCornersMetric.Physical,
		},
		{
			text: title(FourCornersMetric.Mental),
			value: FourCornersMetric.Mental,
			sortable: true,
			metric: FourCornersMetric.Mental,
		}
	]

	titleSixMoments(text: string): string {
		const words = text.split(' ');
		const initial = title(words[0]).charAt(0);
		const abbrev = title(words[1]).slice(0,3);
		return initial.concat('.').concat(abbrev);
	}
	headersSixMoments: Array<DataTableHeader<any> & { metric?: SixMomentsMetric}> = [
		{
			text: this.titleSixMoments(SixMomentsMetric.AttackingOrganization),
			value: SixMomentsMetric.AttackingOrganization,
			sortable: true,
			metric: SixMomentsMetric.AttackingOrganization,
		},
		{
			text: this.titleSixMoments(SixMomentsMetric.AttackingTransition),
			value: SixMomentsMetric.AttackingTransition,
			sortable: true,
			metric: SixMomentsMetric.AttackingTransition,
		},
		{
			text: this.titleSixMoments(SixMomentsMetric.AttackingSetPlays),
			value: SixMomentsMetric.AttackingSetPlays,
			sortable: true,
			metric: SixMomentsMetric.AttackingSetPlays,
		},
		{
			text: this.titleSixMoments(SixMomentsMetric.DefendingOrganization),
			value: SixMomentsMetric.DefendingOrganization,
			sortable: true,
			metric: SixMomentsMetric.DefendingOrganization,
		},
		{
			text: this.titleSixMoments(SixMomentsMetric.DefendingTransition),
			value: SixMomentsMetric.DefendingTransition,
			sortable: true,
			metric: SixMomentsMetric.DefendingTransition,
		},
		{
			text: this.titleSixMoments(SixMomentsMetric.DefendingSetPlays),
			value: SixMomentsMetric.DefendingSetPlays,
			sortable: true,
			metric: SixMomentsMetric.DefendingSetPlays,
		},
	]

	headersCrisp: Array<DataTableHeader<any> & { metric?: CrispMetric}> = [
		{
			text: title(CrispMetric.Competitive),
			value: CrispMetric.Competitive,
			sortable: true,
			metric: CrispMetric.Competitive,
		},
		{
			text: title(CrispMetric.Resilience),
			value: CrispMetric.Resilience,
			sortable: true,
			metric: CrispMetric.Resilience,
		},
		{
			text: title(CrispMetric.Intelligence),
			value: CrispMetric.Intelligence,
			sortable: true,
			metric: CrispMetric.Intelligence,
		},
		{
			text: title(CrispMetric.Speed),
			value: CrispMetric.Speed,
			sortable: true,
			metric: CrispMetric.Speed,
		},
		{
			text: title(CrispMetric.Presence),
			value: CrispMetric.Presence,
			sortable: true,
			metric: CrispMetric.Presence,
		},
	]

	headersPersonality: Array<DataTableHeader<any> & { metric: PersonalityMetric}> = [
		{
			text: PersonalityMetric.MentalToughnes,
			value: PersonalityMetric.MentalToughnes,
			sortable: true,
			metric: PersonalityMetric.MentalToughnes,
		},
		{
			text: PersonalityMetric.Coachability,
			value: PersonalityMetric.Coachability,
			sortable: true,
			metric: PersonalityMetric.Coachability,
		},
		{
			text: PersonalityMetric.MentalExecution,
			value: PersonalityMetric.MentalExecution,
			sortable: true,
			metric: PersonalityMetric.MentalExecution,
		}
	]

	get TableHeaders(): Array<DataTableHeader<any>>{
		if( this.isViewFourCorners ) return this.headersBasic.concat(this.headersFourCorners);
		if( this.isViewSixMoments ) return this.headersBasic.concat(this.headersSixMoments);
		if( this.isViewCrisp ) return this.headersBasic.concat(this.headersCrisp);
		if( this.isViewPersonality ) return this.headersBasic.concat(this.headersPersonality);
		return this.headersBasic.concat(this.headersPhysical);
	} 

	/**
	 * Athlete Metrics listed in order identical to headers
	 */
	get PhysicalMetrics(): AthleteMetric[]{
		return this.headersPhysical.filter(h => h.metric !== undefined).map(h => h.metric!);
	}	
	get FourCornersMetrics(): FourCornersMetric[]{
		return this.headersFourCorners.filter(h => h.metric !== undefined).map(h => h.metric!);
	}
	get SixMomentsMetrics(): SixMomentsMetric[]{
		return this.headersSixMoments.filter(h => h.metric !== undefined).map(h => h.metric!);
	}
	get CrispMetrics(): CrispMetric[]{
		return this.headersCrisp.filter(h => h.metric !== undefined).map(h => h.metric!);
	}
	get PersonalityMetrics(): PersonalityMetric[]{
		return this.headersPersonality.filter(h => h.metric !== undefined).map(h => h.metric!);
	}

	tableOptions: DataOptions = {
		page: 1,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [true],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};

	get TableLoading(): boolean{
		return !orgAdminStore.loadOrganizationPlayersInitialized || orgAdminStore.loadOrganizationPlayersLoading;
	}
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}

	goToPlayerProfile(athleteId: string): void {
		this.$router.push({
			name: CoachViewAthlete,
			params: { athleteId }
		})
	}
}
