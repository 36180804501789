
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import { TeamModel } from '@/models/team';

@Component
export default class TeamsSummary extends Mixins(VuetifyMixin){
	@Prop({ default: [] }) private teams: TeamModel[];
	@Prop({ default: false }) private onlyCurrent: boolean;

	get FirstTeam(): string{
		if(this.teams.length === 0) return "None";
		const [team] = this.teams;
		return team.name;
	}
	get MoreTeams(): number | null{
		if(!this.onlyCurrent && this.teams.length >= 2) return this.teams.length - 1;
		return null;
	}
}
