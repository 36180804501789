
import { Component, Mixins } from 'vue-property-decorator';
import { mdiDotsVertical, mdiTrashCanOutline } from '@mdi/js';
import OrgAdminModule from '../../store/orgAdmin/OrgAdmin.store';
import { getModule } from 'vuex-module-decorators'
const orgAdminStore = getModule(OrgAdminModule);
import { DataTableHeader } from 'vuetify';
import { OrgResolverMixin } from '@/mixins/OrgResolverMixin';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import Page from '@/views/Page.vue';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import { StaffMember } from '@/models/team/StaffMember';
import { ContextMenuItem } from '@/../types/interfaces';
import { InvitationStatus } from '@/../types/enums';
// import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
// import { OrganizationModel } from '@/models/organization/OrganizationModel';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import OrganizationCreateStaffInfo from '@/components/admin/OrganizationCreateStaffInfo.vue';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

@Component({
	components: {
		ConfirmationDialog,
		Page,
		OrgCard,
		OrganizationCreateStaffInfo,
	}
})
export default class OrgAdminManageStaff extends Mixins(OrgResolverMixin, VuetifyMixin){
	mdiDotsVertical = mdiDotsVertical;
	created(): void{
		this.loadOrgStaff();
	}

	get TableLoading(): boolean{
		return !orgAdminStore.loadOrganizationStaffInitialized || orgAdminStore.loadOrganizationStaffLoading;
	}

	loadOrgStaff(): Promise<void>{
		return orgAdminStore.loadOrganizationStaff({organizationId: this.CurrentOrganizationId});
	}

	getStaffItems(staff: StaffMember): ContextMenuItem[]{
		const items: ContextMenuItem[] = [
			{
				text: 'Remove',
				icon: mdiTrashCanOutline,
				click: () => this.confirmDeleteStaff(staff),
			}
		]
		return items;
	}

	showConfirmDeleteStaff: boolean = false;
	selectedDeleteStaffMember: StaffMember | null = null;
	get ConfirmDeleteText(): string{
		if(this.selectedDeleteStaffMember === null) return "";
		return `Remove "${this.selectedDeleteStaffMember.firstName} ${this.selectedDeleteStaffMember.lastName}" from ${this.CurrentOrganization.name}?`; 
	}
	confirmDeleteStaff(staff: StaffMember): void{
		this.selectedDeleteStaffMember = staff;
		this.showConfirmDeleteStaff = true;
	}
	get RemoveStaffLoading(): boolean{
		return orgAdminStore.removeStaffLoading;
	}
	private async deleteStaff(staff: StaffMember){
		await orgAdminStore.removeStaff({ 
			organizationId: this.CurrentOrganizationId,
			staffId: staff.id
		});
		this.showConfirmDeleteStaff = false;
		this.selectedDeleteStaffMember = null;
	}

	showConfirmAddStaff: boolean = false;
	addStaffFormValue = this.resetAddStaffForm();
	private resetAddStaffForm(){
		return this.addStaffFormValue = {
			valid: false,
			staffMembers: [],
			sendStaffInvitations: true,
		};
	}
	confirmAddStaff(): void{
		this.resetAddStaffForm();
		this.showConfirmAddStaff = true;
	}
	closeAddStaff(): void{
		this.showConfirmAddStaff = false;
		this.resetAddStaffForm();
	}
	get AddStaffLoading(): boolean{
		return orgAdminStore.patchOrganizationLoading;
	}
	private async submitAddStaff(){
		const orgCopy: OrganizationModel = this.CurrentOrganization.copy();
		orgCopy.staffMembers.push(...this.addStaffFormValue.staffMembers);
		await orgAdminStore.patchOrganization({
			organization: orgCopy,
			options: { inviteStaff: this.addStaffFormValue.sendStaffInvitations }
		});
		await this.loadOrgStaff();
		this.showConfirmAddStaff = false;
		this.resetAddStaffForm();
	}

	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'name',
			sortable: false,
		},
		{
			text: 'Title',
			value: 'title',
			sortable: false,
		},
		// {
		// 	text: 'Teams(s)',
		// 	value: 'TeamCount',
		// 	sortable: false,
		// },
		{
			text: 'Email',
			value: 'email',
			sortable: false,
		},
	];
	
	get StaffList(): StaffMember[]{
		return orgAdminStore.staffList;
	}
}
