import { Component, Prop, Vue } from 'vue-property-decorator';
import OrgAdminModule from '@/store/orgAdmin/OrgAdmin.store';
import { getModule } from 'vuex-module-decorators'
import { OrganizationModel } from '@/models/organization/OrganizationModel';
const orgAdminStore = getModule(OrgAdminModule);

async function resolveOrganizationFromUrl(to, __, next): Promise <void> {
	const { organizationId } = to.params;
	if(organizationId) {
		orgAdminStore.loadOrganizationById({ organizationId });
	}
	next();
}

@Component({
	beforeRouteEnter: resolveOrganizationFromUrl,
	beforeRouteUpdate: resolveOrganizationFromUrl,
})
export class OrgResolverMixin extends Vue {
	@Prop({ required: true }) organizationId: string;
	async loadCurrentOrganization(): Promise<void>{
		if(this.CurrentOrganizationId){
			console.warn("OrgResolverMixin No CurrentOrganization to inPitialize");
			return;
		}
		await orgAdminStore.loadOrganizationById({organizationId: this.CurrentOrganizationId});
	}

	get CurrentOrganizationLoading(): boolean{
		return orgAdminStore.loadOrganizationByIdLoading;
	}
	get CurrentOrganizationId(): string | null{
		if(this.organizationId !== undefined) return this.organizationId;
		return null;
	}
	get CurrentOrganization(): OrganizationModel | null{
		if (this.CurrentOrganizationId === null) return null;
		const org = orgAdminStore.organizations.find(o => o.id === this.CurrentOrganizationId);
		return org ? org : null;
	}

}
